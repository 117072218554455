@use '@angular/material' as mat;
@import '@angular/material/theming';

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/symbols.woff2') format('woff2');
}


@font-face {
  font-family: 'Heebo';
  src: url('./assets/Heebo-VariableFont_wght.ttf');
}

$typography: mat.define-typography-config($font-family: 'Heebo',
$headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.define-typography-level(34px, 40px, 400, 'Heebo', normal),
$headline-5: mat.define-typography-level(40px, 45px, 500, 'Heebo', normal), // H1
$headline-6: mat.define-typography-level(32px, 32px, 500, 'Heebo', normal), // h2
$subtitle-1: mat.define-typography-level(24px, 30px, 500, 'Heebo', normal), // h3
$subtitle-2: mat.define-typography-level(20px, 26px, 500, 'Heebo', normal), // h4
$body-1: mat.define-typography-level(20px, 24px, 400, 'Heebo', normal), // Bold P
$body-2: mat.define-typography-level(16px, 22px, normal, 'Heebo', normal), // P
$caption: mat.define-typography-level(14px, 18px, 400, 'Heebo', normal), // Caption
$button: mat.define-typography-level(14px, 22px, 400, 'Heebo', normal)); // bolder body


$primary-scheme: (
        50 : #f8f2e7,
        100 : #ecdfc2,
        200 : #e0c99a,
        300 : #d4b372,
        400 : #caa353,
        500 : #c19335,
        600 : #bb8b30,
        700 : #b38028,
        800 : #ab7622,
        900 : #9e6416,
        A100 : #ffead2,
        A200 : #ffd39f,
        A400 : #ffbb6c,
        A700 : #ffb052,
        contrast: (
                50 : #000,
                100 : #000,
                200 : #000,
                300 : #000,
                400 : #000,
                500 : #fff,
                600 : #000,
                700 : #000,
                800 : #fff,
                900 : #fff,
                A100 : #000,
                A200 : #000,
                A400 : #000,
                A700 : #000,
        )
);
$accent-scheme: (
        100: #ffe57f,
        500: #c19335,
        700: #ffc400,
        contrast: (
                100: rgba(255, 255, 255, 0.87),
                500: #fff,
                700: #ffff,
        )
);

$primary-palette: mat.define-palette($primary-scheme);
$accent-palette: mat.define-palette($accent-scheme);
// $warn-palette: mat.define-palette($warn-scheme);

$my-theme: mat.define-light-theme((
        color: (
                primary: $primary-palette,
                accent: $accent-palette,
        ),
        typography: $typography,
));

@include mat.all-component-themes($my-theme);
@include mat.all-component-typographies($typography);

@include mat.core();

.material-symbols-outlined {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Symbols Outlined', serif;
  font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

// *** Contrast fix example of custom accent color ***
.mat-mdc-raised-button,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: mat.get-color-from-palette($primary-palette,
          default-contrast) !important;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1); //Insert custom rgba contrast color
  color: mat.get-color-from-palette($primary-palette,
          default-contrast) !important;
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-icon-color: mat.get-color-from-palette($primary-palette default-contrast);
  --mat-mdc-fab-color: mat.get-color-from-palette($primary-palette,
          default-contrast);
}

* {
  --mdc-checkbox-selected-checkmark-color: #fff !important; //Using mat.get-color-from-palette() doesn't work for me so I hardcoded the color
}
