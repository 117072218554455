@import 'variables';
@import 'theme';

/** Make sure every pixel is used. */
html {
  height: 100%;
  width: 100%;
}

mat-form-field {
  width: 260px;
}

button {
  max-width: 200px;
}

a {
  color: $maf-primary;
  text-decoration: underline;
}

a:visited {
  color: $maf-error;
}

h1 {
  color: $maf-primary;
  word-break: break-all;
}

.maf-error {
  background-color: $maf-error;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

.maf-fancy {
  color: $maf-primary;
}

/* use this to grow */
.filler {
  display: flex;
  flex-grow: 1;
}

/* Wrap headers, footers and content in this one */
.maf-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}


/* Plain is just a holder for content on pages w/o hero images.*/
.maf-plain-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  max-width: $maf-max-width;
  width: 100%;
}

.maf-admin-content {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: 100px;
  margin-top: 8px;
}

@media screen and (min-width: 400px) {
  .maf-plain-content > * {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: $maf-max-width) and (min-width: $maf-small) {
  .maf-plain-content > * {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: 1215px) and (min-width: 400px) {
  .maf-plain-content > * {
    padding-left: 65px;
    padding-right: 65px;
  }
}


@media screen and (max-width: 400px) {
  .maf-plain-content > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
